export const v18: any = {
    version: '1.8',
    subversion: [
        {
            version: '1.8.9',
            date: '26/04/2023',
            content: [
                'SECURITE : Grosse mise à jour de sécurité.',
            ],
        },
        {
            version: '1.8.8 HOTFIX 2',
            date: '14/05/2022',
            content: [
                'LISTE : Correction d\'un bug sur l\'ouverture des formulaires d\'édition ainsi que des sidebars.',
                'LISTE : Résolution d\'un bug de pagination sur les tableaux.',
                'SECURITE : Mises à jour de sécurité.',
            ],
        },
        {
            version: '1.8.8 HOTFIX 1',
            date: '04/04/2022',
            content: [
                'LISTE : Résolution d\'un bug lors du chargement de plus d\'items.',
                'SIDEBAR : Résolution d\'un soucis de performance dans la sidebar.',
                'SECURITE : Mises à jour de sécurité.',
            ],
        },
        {
            version: '1.8.8',
            date: '03/04/2022',
            content: [
                'GOODIES : En mode tableau, ajout de la devise à côté du prix.',
                'FILTRE : Résolution d\'un bug sur le filtre "Oeuvre Original".',
                'FILTRE : Résolution d\'un bug sur le filtre "Label".',
                'MUSIQUE : En mode tableau, affichage de l\'artiste.',
                'CONSOLES & ACCESSOIRES  : Dans les filtres, quand les types "Consoles" et "Accessoires" sont sélectionnés, on affiche le filtre "Types".',
                'SECURITE : Mises à jour de sécurité.',
            ],
        },
        {
            version: '1.8.7 HOTFIX 1',
            date: '19/03/2022',
            content: [
                'Dans la liste des items en mode tableau, en visitant le compte de quelqu\'un d\'autre, la colonne "Action" ne s\'affichait plus, elle a été rétablie.',
                'Dans la catégorie "Consoles & Accessoires", le "support" ne s\'affiche que lorsque l\'item est au type "Console".',
            ],
        },
        {
            version: '1.8.7',
            date: '12/03/2022',
            content: [
                'FORMULAIRE D\'ITEM : Déplacement de la saisie de la note et de la date "Terminé le" dans l\'onglet "COLLECTION".',
                'FORMULAIRE D\'ITEM : La date "Terminé le" ne s\'affiche que lorsque le statut est "Terminé".',
                'FORMULAIRE D\'ITEM : L\'onglet "CONTENU" ne s\'affiche que pour les livres afin de modifier les tomes.',
                'FORMULAIRE D\'ITEM : Tri automatique par ordre alphabétique des défauts.',
                'FORMULAIRE D\'ITEM : La console par défaut dans "Jeux Vidéo" et "Consoles & Accessoires" est passé de "Playstation 4" à "Playstation 5".',
                'SIDEBAR : On cache la date "Terminé le" quand le statut n\'est pas sur "Terminé".',
                'SIDEBAR : Dans la section "Commentaire", on fait des retours à la ligne automatique. Cela évite que le texte déborde.',
                'SECURITE : Mises à jour de sécurité.',
            ],
        },
        {
            version: '1.8.6',
            date: '05/03/2022',
            content: [
                'FORMULAIRE D\'ITEM : Dans l\'onglet collection (contenu pour les livres), ajout d\'un champs "Défaut" permettant de rajouter des défauts liés aux objets.',
                'FORMULAIRE D\'ITEM : Ajout de la possibilité de faire des retours à la ligne dans la section commentaire.',
                'FORMULAIRE D\'ITEM : Ajout d\'une limite de 1000 caractères dans la section commentaire.',
                'FILTRE : Ajout du filtre par défauts.',
                'SIDEBAR : Ajout des défauts.',
                'EXPORT CSV : Prise en compte des filtres.',
                'EXPORT CSV : Les en-têtes sont en français.',
                'EXPORT CSV : Prise en compte des livres lu.',
                'EXPORT CSV : Résolution de bugs liés aux dates.',
                'EXPORT CSV : Suppression des colonnes des liens d\'images et externe.',
                'EXPORT CSV : Résolution d\'un bug d\'affichage sur les objets non notés.',
                'EXPORT CSV : Résolution d\'un bug d\'affichage sur les oeuvres liées.',
                'AUTRE : Les genres sont maintenant automatiquement rangés par ordre alphabétique automatiquement.',
                'SECURITE : Mises à jour de sécurité.',
            ],
        },
        {
            version: '1.8.5',
            date: '19/01/2022',
            content: [
                'Tri du graphique de la page d\'accueil par ordre croissant.',
                'Résolution d\'un bug d\'affichage lors de l\'affichage de la visualisation d\'une fiche dans la barre latérale de certains goodies.',
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.4.1',
            date: '18/10/2021',
            content: [
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.4',
            date: '30/07/2021',
            content: [
                'Un système de vérification de model de fiche à été mis en place pour plus de pertinence. De nouvelles fiches vont arriver au fur et à mesure.',
                'Une amélioration va nous permettre d\'alimenter plus rapidement et efficacement les différentes listes (genres, editeurs, etc.).',
                'Résolution de bugs d\'affichages des modals sur la page d\'accueil.',
                'Résolution d\'un bug d\'affichage sur le bas de page sur la page d\'accueil en mode mobile.',
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.3',
            date: '04/07/2021',
            content: [
                'On peut maintenant faire glisser les lignes du tableau des tomes dans le formulaire.',
                'Petite amélioration dans le formulaire d\'ajout de tome.',
                'Déplacement de la scrollbar des dernières news sur la page d\'accueil. Elle ne prend plus en compte le menu.',
                'Les en-têtes des tableaux des derniers changements et des tomes ne suivent plus le scroll pour une meilleure visibilité.',
                'Les champs vides sont maintenant cachés dans les fiches des items.',
                'Résolution de bugs quand on crée une nouvelle fiche. Parfois les données disparaissaient dans le formulaire après la création.',
                'Résolution du bug du nombre de tomes dans l\'export csv.',
                'Optimisation de l\'application.',
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.2',
            date: '16/05/2021',
            content: [
                'Dans la barre de menu, en cliquant sur son pseudo, on peut maintenant envoyer une suggestion ou un bug via un formulaire.',
                'Ajout des tomes triples dans le choix de l\'édition d\'un tome.',
                'Ajout des éditeurs "Meian", "Noeve" et "ManEd" dans les catégories "Artbooks" et "Mangas".',
                'Supression du filtre par défaut.',
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.1',
            date: '08/04/2021',
            content: [
                'Il y aura désormais toujours au moins un tome de déclaré dans la fiche des livres.',
                'Dans la liste des tomes, résolution d\'un bug d\'affichage sur les petits écrans et les smartphones.',
                'Amélioration de l\'affichage de la barre d\'actions pour qu\'elle soit plus lisible.',
                'Résolution d\'un bug d\'affichage lors de la modification d\'une fiche. Les données modifiées affectaient l\'affichage des items dans la liste.',
                'Dans la liste des éditions, ajout de l\'édition "Deluxe".',
                'Mise à jour sécurité.',
            ],
        },
        {
            version: '1.8.0',
            date: '06/04/2021',
            content: [
                'Mise à jour du système d\'authentification, plus sécurisé.',
                'Changement de tout l\'espace utilisateur.',
                'Refonte de toute l\'API qui est beaucoup plus sécurisée que l\'ancienne.',
                'Ajout de la fonctionnalité de tri (par titre et note) dans la liste des items.',
                'Ajout d\'une zone de texte permettant de laisser un commentaire dans une fiche (seulement visible par l\'utilisateur).',
                'Nouveau système de gestion des tomes d\'un item de type "Livre".',
                'Le chercheur de model de fiche a été refait.',
                'En mode "Liste", le lien externe a été déplacé dans la barre d\'actions.',
                'En mode liste, un bouton "Afficher plus" a été ajouté. Cela permet d\'afficher plus d\'éléments si cela ne fonctionne pas quand on est en bas de la page (notamment sur mobile).',
                'Ajout du lien externe dans la barre d\'actions en mode tableau.',
                'Les dernières mises à jour passe de 10 à 50 éléments, et sont triées par catégorie.',
                'Dans la modal "Planning Manga News", les boutons "Profil Manga News" et "Planning Manga News" ' +
                'sont maintenant cachés quand aucun utilisateur n\'est trouvé.',
                'Mise à jour sécurité.',
            ],
        },
    ],
};
